@import "minima";
@import "minima-overrides";
@import "custom";
@import "copycode";

/* adjust for when not enough posts */
.page-content {
	position: relative;
}
.rss-subscribe {
	position: absolute;
	bottom: 1rem;
}

.footer-col-1 {
	width: calc(31% - (30px / 2)) !important;
}
.footer-col-2 {
	width: calc(24% - (30px / 2)) !important;
}

