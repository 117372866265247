div.highlight {
	position: relative;
}

pre.highlight {
	// padding: 8px 12px;
	padding: 10px 12px;

	// override skeleton styles
	> code {
		border: 0;
		overflow-x: auto;
		padding-right: 0;
		padding-left: 0;
	}

	&.highlight {
		overflow: auto;
		white-space: pre;
		word-wrap: normal;
	}

	// copy code to clipboard
	button.copy {
		// top: -1px;
		// right: -1px;

		// border-top: none;
		// border-right: none;
		// border-bottom-left-radius: 2px;

		// background: none;
		// border: 1px solid;

		// padding: 2px 8px;

		// background: rgba(0, 0, 0, 0.25);
		// background: hsla(260, 100%, 93%, 1);

		// font-weight: 500;

		position: absolute;
		top: 7px;
		right: 8px;

		background: hsla(260, 100%, 100%, 1);
		color: hsla(260, 100%, 50%, 1);
		border: none;
		border-radius: 3px;

		padding: 4px 12px 5px 12px;
		font-size: 1rem;

		cursor: pointer;

		&.copy-succ {
			// background: hsl(120, 100%, 50%);
			background: hsl(120, 100%, 88%);

			animation: fadeBackground 1.5s ease forwards;
			animation-delay: 1s;

			@keyframes fadeBackground {
				0% {
					background-color: hsl(120, 100%, 88%);
				}
				100% {
					background-color: hsla(260, 100%, 100%, 1);
				}
			}
		}


		&.copy-fail {
			
		}
	}
}

